import styled from 'styled-components';
import { ReactComponent as Button } from '../../assets/images/back-arrow.svg';

export const Overlay = styled.div<{ modalOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: ${({ modalOpen }) => (modalOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${({ theme }) => `border-radius: ${theme.borderRadius}`};
`;

export const BackButton = styled(Button)`
  margin-right: 9px;
  cursor: pointer;
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 30px;

  ${({ theme }) =>
    `font-size: ${theme.fontSize.mobileTitleBig}; font-weight: ${theme.fontWeight.bold}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    max-width: 180px;
    margin-bottom: 18px;

    ${({ theme }) => `font-size: ${theme.fontSize.middle};`};
  }
`;

export const ConversionRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 649px;

  > * {
    &:nth-child(0) {
      margin-bottom: -20px;
    }
    &:nth-child(1) {
      margin-bottom: -20px;
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 8px;
    > * {
      &:nth-child(1) {
        margin-bottom: -20px;
        padding-bottom: 13px;
      }
    }
  }
`;

export const FeeRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > * {
    &:nth-child(0) {
      margin-bottom: -20px;
    }
    &:nth-child(1) {
      margin-bottom: -20px;
      flex-wrap: wrap;
    }
    &:nth-child(2) {
      flex-wrap: wrap;
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 8px;

    > * {
      &:nth-child(1) {
        flex-wrap: wrap;
        padding-bottom: 20px;
      }
      &:nth-child(2) {
        flex-wrap: wrap;
      }
    }
  }
`;

export const ConfirmationRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${({ theme }) => `${theme.device.xs}`} {
    flex-direction: column;
  }
`;

export const EmailRow = styled.div<{ direction: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  > * {
    &:nth-child(0) {
      margin-bottom: -20px;
    }
    &:nth-child(1) {
      margin-bottom: -20px;
    }
  }

  ${({ direction }) =>
    direction === 'rtl' ? 'flex-direction: row-reverse' : ''};

  @media ${({ theme }) => `${theme.device.xs}`} {
    word-break: break-word;
    text-align: center;

    @media ${({ theme }) => `${theme.device.xs}`} {
      margin-bottom: 0px;
      > * {
        &:nth-child(1) {
          margin-bottom: -20px;
          padding-bottom: 13px;
        }
      }
    }
  }
`;

export const ConfirmationText = styled.div`
  ${({ theme }) => `font-size: ${theme.fontSize.mobileTitleBig};
    font-weight: ${theme.fontWeight.bold}`};
  padding: 20px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    display: flex;
    align-self: flex-start;
    padding: 0px 0px 15px 0px;
    ${({ theme }) => `font-size: ${theme.fontSize.middle};`}
  }
`;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateDifference } from '../../utils/index';
import { Time, TimeLeft, CountDownTimerProps } from './interfaces';
import {
  Timer,
  TimeoutMessage,
  TimerDesc,
  TimeSlot,
  TimeBlock,
  TimeName,
  Separator,
} from './styled';

const timeStart = {
  hours: 0,
  minutes: 0,
  seconds: 0,
};

const renderTimer = (isTimeout: boolean, time: Time, t: any): JSX.Element => {
  return (
    <Timer isTimeout={isTimeout}>
      <TimeBlock>
        <TimeSlot>{`${time.hours
          .toString()
          .padStart(2, '0')
          .charAt(0)}`}</TimeSlot>
        <TimeSlot>{`${time.hours
          .toString()
          .padStart(2, '0')
          .charAt(1)}`}</TimeSlot>
        <TimeName>{t('hours')}</TimeName>
      </TimeBlock>
      <Separator>:</Separator>
      <TimeBlock>
        <TimeSlot>{`${time.minutes
          .toString()
          .padStart(2, '0')
          .charAt(0)}`}</TimeSlot>
        <TimeSlot>{`${time.minutes
          .toString()
          .padStart(2, '0')
          .charAt(1)}`}</TimeSlot>
        <TimeName>{t('minutes')}</TimeName>
      </TimeBlock>
      <Separator>:</Separator>
      <TimeBlock>
        <TimeSlot>{`${time.seconds
          .toString()
          .padStart(2, '0')
          .charAt(0)}`}</TimeSlot>
        <TimeSlot>{`${time.seconds
          .toString()
          .padStart(2, '0')
          .charAt(1)}`}</TimeSlot>
        <TimeName>{t('seconds')}</TimeName>
      </TimeBlock>
    </Timer>
  );
};

const CountDownTimer = ({
  timeout,
  timestamp,
  onTimeout,
}: CountDownTimerProps): JSX.Element => {
  const { t } = useTranslation();
  const [time, setTime] = useState<Time>(timeStart);
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const now = new Date();
    const result: TimeLeft = getDateDifference(now, timeout);

    setTime({
      hours: result.hour,
      minutes: result.minute,
      seconds: result.second,
    });
  }, [timestamp, timeout]);

  const reset = () => {
    setTime(timeStart);
    onTimeout();
    setIsTimeout(true);
  };

  const tick = () => {
    if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
      reset();
    } else if (time.hours !== 0 && time.minutes === 0 && time.seconds === 0) {
      setTime({ hours: time.hours - 1, minutes: 59, seconds: 59 });
    } else if (time.seconds === 0) {
      setTime({ hours: time.hours, minutes: time.minutes - 1, seconds: 59 });
    } else {
      setTime({
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds - 1,
      });
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);

    return () => clearInterval(timerId);
  });

  return (
    <>
      <TimeoutMessage isTimeout={isTimeout}>
        {isTimeout ? t('transactionTimeout') : t('transactionTimeRunning')}
      </TimeoutMessage>
      <TimerDesc>
        {isTimeout
          ? t('transactionTimerDescriptionTimeout')
          : t('transactionTimerDescription')}
      </TimerDesc>
      {renderTimer(isTimeout, time, t)}
    </>
  );
};

export default CountDownTimer;

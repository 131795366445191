import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { round } from 'reliable-round';
import {
  TransactionIdDesc,
  CopyRow,
  QRCodeWrapper,
  TimerRow,
  WalletRow,
  TransactionRow,
  Headline,
  ProgressRow,
  Overlay,
  SuccessScreen,
  TransactionLink,
} from './styled';
import SummaryField from '../shared/SummaryField';
import useSwapById, { SwapIdResponse } from '../../hooks/useSwapById';
import Layout from '../shared/Layout';
import CountDownTimer from './CountDownTimer';
import CustomQRCode from './CustomQRCode';
import { CompleteTransactionParam } from './interfaces';
import ProgressBar from './ProgressBar';
import { ButtonSize } from '../shared/consts/index';
import {
  getRefreshInterval,
  isTransactionCompleted,
  calculateProgress,
} from './helpers';
import { SwapValidateResponse } from '../TransactionSummary/interfaces';
import Modal from '../shared/modal';
import { ModalType, ModalFuncProps } from '../shared/modal/interfaces';
import { SwapErrorResponse } from '../shared/interfaces/response.interface';
import TermsText from '../shared/terms';
import {
  WrapperTop,
  WrapperBottom,
  ContentTop,
  ContentBottom,
} from '../shared/styled/styled';
import SuccessIcon from '../../assets/images/success-icon.png';
import Button from '../shared/button';

const oneMinute = 60 * 1000;

const getTransactionAddress = (url: string | undefined): string => {
  const splited = url?.split('/');

  return splited !== undefined ? splited[4] : '';
};

const CompleteTransaction = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const errorModal = useRef<ModalFuncProps>(null);
  const [isTimeout, setIsTimeout] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(oneMinute);
  const [enableRef, setEnableRef] = useState(false);
  const [currencyIn, setCurrencyIn] = useState('');
  const [isSuccess, setSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const { state } = useLocation<SwapValidateResponse>();
  const { id } = useParams<CompleteTransactionParam>();
  const { data, refetch } = useSwapById(
    id,
    refreshInterval,
    enableRef,
    (response: SwapIdResponse) => {
      setEnableRef(true);
      setCurrencyIn(response.currency_in);

      if (parseFloat(response.amount_received) > 0) {
        const transactionProgress = calculateProgress(
          parseFloat(response.amount_received),
          parseFloat(response.amount_requested)
        );

        if (transactionProgress > 99 && transactionProgress < 100) {
          setProgress(99);
        } else if (transactionProgress === 100) {
          setProgress(100);
        } else {
          setProgress(round(transactionProgress, 0));
        }
      }

      if (
        isTransactionCompleted(
          response.swap_status,
          response.amount_received,
          response.amount_requested
        )
      ) {
        setSuccess(true);
      }
    },
    (errors: SwapErrorResponse) => {
      setModalOpen(true);
      errorModal.current?.openErrorMessage(errors.msg);
    }
  );

  const handleCopy = (text: string | undefined) => {
    if (text) {
      navigator.clipboard.writeText(text);
    }
  };

  const handleTimeout = () => {
    setIsTimeout(true);
  };

  useEffect(() => {
    if (state) {
      const interval = getRefreshInterval(state.chain_in);

      setRefreshInterval(interval);
    }
  }, [state]);

  useEffect(() => {
    refetch();
  }, [refetch, enableRef]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleBackHome = (): void => {
    history.push('/');
  };

  return (
    <Layout>
      <>
        <WrapperTop>
          <Overlay modalOpen={modalOpen} />
          <ContentTop>
            <Headline>{t('headline.transationComplete')}</Headline>
            <TransactionIdDesc>{t('transactionDescription')}</TransactionIdDesc>
            <QRCodeWrapper>
              {data && <CustomQRCode value={data.deposit_address} />}
            </QRCodeWrapper>
            <TransactionRow>
              <SummaryField
                label={t('transactionAmount')}
                value={`${data && data.amount_requested} ${currencyIn}`}
              />
            </TransactionRow>
            {progress > 0 && (
              <ProgressRow>
                {data && (
                  <SummaryField
                    label={t('amountStatus')}
                    value={`${data.amount_received}/${data.amount_requested} ${currencyIn}`}
                  />
                )}
                {data && (
                  <ProgressBar
                    value={progress}
                    max={100}
                    width={570}
                    amountReceived={data.amount_received}
                    amountRequested={data.amount_requested}
                    chainSymbol={currencyIn}
                  />
                )}
              </ProgressRow>
            )}
            <WalletRow>
              <SummaryField label="Swap ID" value={`${data && data.swap_id}`} />
              <SummaryField
                label="Deposit address"
                limitedWidth
                value={data ? data.deposit_address : ''}
                extra={
                  <CopyRow
                    data-test-id="copyAddress"
                    onClick={() => handleCopy(data && data.deposit_address)}
                  >
                    {t('copyAddress')}
                  </CopyRow>
                }
              />
              <SummaryField
                label="Recipient"
                limitedWidth
                value={`${data && data.destination_address}`}
              />
            </WalletRow>
          </ContentTop>
        </WrapperTop>
        <WrapperBottom>
          <ContentBottom>
            {isSuccess ? (
              <SuccessScreen>
                <Headline>Success!</Headline>
                <TransactionIdDesc>
                  Your transaction is complete. We’ve sent you an email with the
                  confirmation. Transaction ID:{' '}
                  <TransactionLink href={data?.chain_out_tx_id}>
                    {getTransactionAddress(data?.chain_out_tx_id)}
                  </TransactionLink>
                </TransactionIdDesc>
                <img src={SuccessIcon} alt="success" />
                <Button size={ButtonSize.Large} onClick={handleBackHome}>
                  {t('button.backHome')}
                </Button>
              </SuccessScreen>
            ) : (
              <TimerRow>
                {data && (
                  <CountDownTimer
                    timestamp={data.timestamp}
                    timeout={data.timeout_timestamp}
                    onTimeout={handleTimeout}
                  />
                )}
              </TimerRow>
            )}
            <TermsText />
          </ContentBottom>
        </WrapperBottom>
        <Modal
          ref={errorModal}
          type={ModalType.ERROR}
          onClose={handleModalClose}
        />
      </>
    </Layout>
  );
};

export default CompleteTransaction;

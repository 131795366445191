import styled from 'styled-components';

export const LinksWrapper = styled.ul`
  display: none;
  align-items: center;

  @media ${({ theme }) => theme.devices.desktop} {
    display: flex;
  }
`;

export const ExpandableWrapper = styled.div`
  display: none;
  position: absolute;
  top: 15px;
  padding-top: 15px;
`;

export const LinksElement = styled.li`
  position: relative;
  margin-left: 15px;

  :hover {
    ${ExpandableWrapper} {
      display: block;
    }
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    margin-left: 20px;
  }

  @media ${({ theme }) => theme.devices.xxlarge} {
    margin-left: 40px;
  }
`;

export const LinkElementExpandable = styled.button`
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer !important;

  div:last-child {
    margin-left: 7px !important;
  }
`;

export const ExpandableInnerWrapper = styled.ul`
  min-width: 164px;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.lightGray};
  box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.1), 1px 4px 6px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  overflow: hidden;
`;

export const ExpandableInnerElement = styled.li`
  padding-left: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  position: relative;
  text-transform: capitalize;

  :hover {
    background-color: white;
    div {
      display: flex;
    }
  }
`;

export const YellowArrowWrapper = styled.div`
  display: none;
  position: absolute;
  right: 16px;
  top: 15px;
  bottom: 0;
`;

export const ArrowWrapper = styled.div`
  margin-left: 7px;
`;

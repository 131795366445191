import React from 'react';
import { Wrapper } from './mobile-menu.styled';
import MenuElementMobile from './menu-element-mobile';
import MenuElementMobileExpandable from './menu-element-mobile-expandable';
import PriceInfo from '../price-info';
import { MENU } from '../consts';

const MobileMenu = () => (
  <Wrapper>
    {MENU.map((item) => {
      if (item.elements) {
        return (
          <MenuElementMobileExpandable
            key={item.name}
            name={item.name}
            url={item.url}
            elements={item.elements}
          />
        );
      }
      return (
        <MenuElementMobile key={item.name} url={item.url} name={item.name} />
      );
    })}
    <PriceInfo showAllInformation />
  </Wrapper>
);

export default MobileMenu;

import { useState, useEffect } from 'react';

const API_URL = `https://api.coingecko.com/api/v3/simple/price?ids=electric-cash&vs_currencies=usd`;

const usePriceELCASH = (): number | undefined => {
  const [price, setPrice] = useState<number>();

  useEffect(() => {
    fetch(API_URL)
      .then((response) => response.json())
      .then((result) => {
        setPrice(result['electric-cash'].usd);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  }, []);

  return price;
};

export default usePriceELCASH;

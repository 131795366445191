import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ showAllInformation?: boolean }>`
  display: flex;
  margin: auto 10px auto auto;

  @media ${({ theme }) => theme.devices.normalMobile} {
    margin: auto 20px;
  }

  @media ${({ theme }) => theme.devices.desktop} {
    margin: auto 10px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    margin: auto 20px;
  }

  @media ${({ theme }) => theme.devices.xxlarge} {
    margin: auto 40px;
  }

  ${({ showAllInformation }) =>
    showAllInformation &&
    css`
      margin: 0 auto !important;
    `};
`;

export const TextWrapper = styled.div<{ showAllInformation?: boolean }>`
  display: flex;
  position: relative;
  margin-left: 8px;
  margin-right: 4px;

  > :first-child {
    display: none;
  }

  ${({ showAllInformation }) =>
    showAllInformation
      ? css`
          > :first-child {
            display: block;
          }
        `
      : css`
          @media ${({ theme }) => theme.devices.desktop} {
            > :first-child {
              display: block;
            }
          }
        `}
`;

export const PoweredByWrapper = styled.div<{ showAllInformation?: boolean }>`
  display: none;
  position: absolute;
  left: 20px;
  bottom: -15px;

  @media ${({ theme }) => theme.devices.desktop} {
    left: 0px;
  }

  ${({ showAllInformation }) =>
    showAllInformation
      ? css`
          display: block;
        `
      : css`
          @media ${({ theme }) => theme.devices.desktop} {
            display: block;
          }
        `}
`;

export const LogoWrapper = styled.div<{ showAllInformation?: boolean }>`
  display: none;
  width: 22px;
  height: 22px;

  ${({ showAllInformation }) =>
    showAllInformation
      ? css`
          display: block;
        `
      : css`
          @media ${({ theme }) => theme.devices.desktop} {
            display: block;
          }
        `}
`;

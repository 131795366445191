import React from 'react';
import { useTranslation } from 'react-i18next';
import { PDFLink } from '../styled/styled';
import { Wrapper } from './styled';

const TermsText = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <PDFLink
        data-test-id="ToC"
        href="../../docs/Terms_of_service.pdf"
        target="_blank"
      >
        {t('tc')}
      </PDFLink>
      &nbsp; and{' '}
      <PDFLink
        data-test-id="PP"
        href="../../docs/Privacy_policy.pdf"
        target="_blank"
      >
        {t('privacy')}
      </PDFLink>
      .
    </Wrapper>
  );
};

export default TermsText;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../shared/text';
import { LinksElement } from './menu.styled';
import { MenuElementProps } from './menu.types';

const MenuElement = ({ url, name }: MenuElementProps) => {
  const { t } = useTranslation();

  return (
    <LinksElement>
      <Text fontSize={18} fontWeight="300">
        <a href={url}>{t(name)}</a>
      </Text>
    </LinksElement>
  );
};

export default MenuElement;

import React from 'react';
import { TextContent } from './text.styled';
import { TextProps } from './interfaces';

const Text = ({
  children,
  align,
  fontSize,
  lineHeight,
  fontWeight,
  margin,
  fontStyle,
  maxWidth,
  color,
  fontSizeMobile,
}: TextProps) => {
  return (
    <TextContent
      align={align}
      fontSize={fontSize}
      fontSizeMobile={fontSizeMobile}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      margin={margin}
      fontStyle={fontStyle}
      maxWidth={maxWidth}
      color={color}
    >
      {children}
    </TextContent>
  );
};

export default Text;

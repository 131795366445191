import React, { Suspense } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import ELCASHConverter from './components/ELCASHConverter';
import TransactionSummary from './components/TransactionSummary';
import CompleteTransaction from './components/CompleteTransaction';
import MessageWindow from './components/shared/MessageWindow';
import theme from './styles/theme';
import '@fontsource/ubuntu/300.css';
import '@fontsource/ubuntu/400.css';
import '@fontsource/ubuntu/700.css';
import { Routes } from './components/shared/consts';
import { Config } from './Config';

const loaderStyle = {
  backgroundImage:
    'linear-gradient(129.04deg, rgba(255, 255, 255, 0.8) 18.9%, rgba(253, 238, 156, 0.761151) 64.96%, rgba(250, 212, 0, 0.7) 104.94%);',
  minHeight: '100vh',
};

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  input[type=number] {
  -moz-appearance: textfield;
}

  body {
    margin: 0;
    padding: 0;
    background: ${theme.colors.white};
    font-family: Ubuntu, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: ${theme.fontWeight.bold};
    line-height: normal;
  }

  h3, h4, h5, h6 {
    font-size: ${theme.fontSize.desktopTitle}
  }

  h1, h2 {
    font-size: ${theme.fontSize.desktopBig};
    @media ${theme.device.xs} {
      font-size: ${theme.fontSize.menuTitle};
    } 
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  button {
    margin: 0;
    padding: 0;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  a {
    width: auto;
    text-decoration: none;
    transition: ${theme.ui.transition('opacity')};
    color: ${theme.colors.black};

    &:hover {
      opacity: ${theme.ui.opacity()};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input:disabled {
    color: ${theme.colors.black};
  }

  [data-reach-dialog-overlay] {
    z-index: 4;
    background: rgba(0,0,0,.33);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
  }

  [data-reach-dialog-content] {
    margin: 10vh auto;
    background: #fff;
  }

  input:focus {
    outline: none;
  }
`;

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div style={loaderStyle} />}>
          <Router>
            <Switch>
              <Route
                exact
                path={Routes.Converter}
                component={ELCASHConverter}
              />
              <Route
                path={`${Routes.Summary}/:token`}
                component={TransactionSummary}
              />
              <Route
                exact
                path={`${Routes.CompleteTransaction}/:id`}
                component={CompleteTransaction}
              />
              <Route
                exact
                path={`${Routes.CompleteTransaction}/:id/success`}
                component={MessageWindow}
              />
              <Route exact path={`${Routes.Error}`} component={MessageWindow} />
              <Route
                path="*"
                component={() => {
                  window.location.href = Config.LandingPageUrl || '/';

                  return null;
                }}
              />
            </Switch>
          </Router>
        </Suspense>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;

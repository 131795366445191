/* eslint-disable import/prefer-default-export */
export const MENU = [
  {
    name: 'Wallets',
    url: 'https://elcash.info/wallets',
  },
  {
    name: 'Blog',
    url: 'https://elcash.info/blog',
  },
  {
    name: 'About Us',
    url: 'https://elcash.info/about-us',
  },
  {
    name: 'wELCASH',
    url: 'https://elcash.info/welcash',
  },
  {
    name: 'Resources',
    url: 'https://elcash.info/resources',
    elements: [
      {
        name: 'whitepaper',
        url: 'https://elcash.info/pdf/electric-cash-whitepaper.pdf',
      },
      {
        name: 'github',
        url: 'https://github.com/electric-cash',
      },
    ],
  },
  {
    name: 'Explorers',
    url: '/explorers',
    elements: [
      {
        name: 'blockchain',
        url: 'https://explorer.electriccash.global/',
      },
      // {
      //   name: 'staking',
      //   url: 'https://explorer.electriccash.global/'
      // },
      // {
      //   name: 'governance',
      //   url: 'https://explorer.electriccash.global/'
      // },
    ],
  },
];

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Menu from './menu/menu';
import PriceInfo from './price-info';
import LanguageSwitcher from './language-switcher';
import Hamburger from './hamburger/hamburger';
import MobileMenu from './mobile-menu';
import {
  BlurBg,
  ImageWrapper,
  InnerWrapper,
  NavWrapper,
  Wrapper,
} from './styled';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as LogoMobile } from '../../assets/images/logo-mobile.svg';
import { Config } from '../../Config';
import useIsMobile from '../../hooks/useIsMobile';
import theme from '../../styles/theme';

export default function Header() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const isMobile = useIsMobile(theme.deviceSize.desktop);

  useEffect(() => {
    if (hamburgerOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [hamburgerOpen]);

  return (
    <>
      <Wrapper>
        <InnerWrapper>
          <a href={Config.LandingPageUrl}>
            <ImageWrapper>{isMobile ? <LogoMobile /> : <Logo />}</ImageWrapper>
          </a>
          <NavWrapper>
            <Menu />
            <PriceInfo />
            {/* <LanguageSwitcher /> */}
            <Hamburger
              open={hamburgerOpen}
              onClick={() => setHamburgerOpen(!hamburgerOpen)}
            />
          </NavWrapper>
        </InnerWrapper>
        {hamburgerOpen && <BlurBg />}
      </Wrapper>
      {hamburgerOpen && <MobileMenu />}
    </>
  );
}

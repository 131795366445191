/* eslint-disable import/prefer-default-export */
const envSettings = window as any;

export class Config {
  static ApiUrl: string = envSettings.API_URL;

  static LandingPageUrl: string = envSettings.LANDING_PAGE_URL;

  static elcashNetwork: string = envSettings.ELCASH_NETWORK;

  static bscAddress: string = envSettings.BSC_ADDRESS;

  static bscscanUrl: string = envSettings.BSCSCAN_URL;
}

import React, { useState, useRef } from 'react';
import {
  RouteComponentProps,
  useLocation,
  useHistory,
  useParams,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import _ from 'lodash';
import {
  Headline,
  FeeRow,
  ConversionRow,
  ConfirmationRow,
  EmailRow,
  BackButton,
  ConfirmationText,
  Overlay,
} from './styled';
import Button from '../shared/button';
import {
  CenteredContent,
  WrapperTop,
  WrapperBottom,
  ContentTop,
  ContentBottom,
} from '../shared/styled/styled';
import SummaryField from '../shared/SummaryField';
import VerificationCode from '../VerificationCode';
import { ButtonSize, Routes } from '../shared/consts/index';
import { ExtendedFormValues } from '../ELCASHConverter/interfaces';
import Layout from '../shared/Layout';
import useSwapValidate from '../../hooks/useSwapValidate';
import useSwapInit from '../../hooks/useSwapInit';
import { SwapValidateResponse, ParamTypes } from './interfaces';
import {
  SwapErrorResponse,
  ErrorType,
} from '../shared/interfaces/response.interface';
import { Chain } from '../ELCASHConverter/consts';
import { ModalFuncProps, ModalType } from '../shared/modal/interfaces';
import Modal from '../shared/modal';
import useLanguageDir from '../../hooks/useLanguageDir';
import TermsText from '../shared/terms';

const getSwapDirectionName = (direction: Chain) => {
  switch (direction) {
    case Chain.ELCASH:
      return 'ELCASH';
    default:
      return 'wELCASH';
  }
};

const PIN_CODE_LENGTH = 6;

const TransactionSummary: React.FC<RouteComponentProps> = () => {
  const errorModal = useRef<ModalFuncProps>(null);
  const history = useHistory();
  const { token } = useParams<ParamTypes>();
  const [pinCode, setPinCode] = useState('');
  const [invalidPin, setInvalidPin] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { state } = useLocation<ExtendedFormValues>();
  const { t } = useTranslation();
  const dir = useLanguageDir();
  const currentLanguage: string =
    i18n.language || window.localStorage.i18nextLng || 'en';

  const { refetch: swapValidate, isLoading } = useSwapValidate(
    token,
    pinCode,
    (res: SwapValidateResponse) => {
      if (res.result === 'success') {
        history.push({
          pathname: `${Routes.CompleteTransaction}/${res.swap_id}`,
          state: res,
        });
      }
    },
    (errorResponse: SwapErrorResponse) => {
      if (errorResponse.error_code === ErrorType.INVALID_PIN) {
        setInvalidPin(errorResponse.msg);
        setIsDisabled(true);
      } else {
        setModalOpen(true);
        errorModal.current?.openErrorMessage(errorResponse.msg);
      }
    }
  );

  const { refetch: swapInit } = useSwapInit(
    state?.email,
    currentLanguage,
    state?.swapDirection.from,
    state?.swapDirection.to,
    state?.walletAddress,
    state?.send,
    (errorResponse: SwapErrorResponse) => {
      setModalOpen(true);
      errorModal.current?.openErrorMessage(errorResponse.msg);
    }
  );

  const handleConfirmConversion = () => {
    swapValidate();
  };

  const handleComplete = (code: string) => {
    setPinCode(code);
    if (invalidPin !== 'No more trials left') {
      setIsDisabled(false);
    }
  };

  const handleBackHome = () => {
    history.push({
      pathname: `${Routes.Converter}`,
      state,
    });
  };

  const handleCodeRefresh = () => {
    swapInit().then((res: any) => {
      if (res.status === 'success') {
        history.push({
          pathname: `${Routes.Summary}/${res.data.session_token}`,
          state,
        });
      }
    });
    setPinCode('');
    setInvalidPin('');
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  if (_.isEmpty(state)) {
    history.push({
      pathname: Routes.Converter,
    });
    return null;
  }
  return (
    <>
      <Layout>
        <>
          <WrapperTop>
            <Overlay modalOpen={modalOpen} />
            <ContentTop>
              <Headline>
                <BackButton id="button-back" onClick={handleBackHome} />{' '}
                {t('headline.transactionSummary')}
              </Headline>
              <ConversionRow>
                <SummaryField
                  id="sendAmount"
                  label={t('input.send')}
                  value={`${parseFloat(state.send)} ${getSwapDirectionName(
                    state.swapDirection.from
                  )}`}
                  dir={dir}
                />
                <SummaryField
                  id="getAmount"
                  label={t('input.get')}
                  value={`${state.get} ${getSwapDirectionName(
                    state.swapDirection.to
                  )}`}
                  dir={dir}
                />
              </ConversionRow>
              <FeeRow>
                <SummaryField
                  id="exchangeFee"
                  label={t('exchangeFee')}
                  value={`${state.exchangeFee} ELCASH`}
                  description={t('exchangeFeeDescription')}
                  dir={dir}
                />
                <SummaryField
                  id="networkFee"
                  label={t('networkFee')}
                  value={`${state.networkFee} ELCASH`}
                  description={t('networkFeeDescription')}
                  dir={dir}
                />
              </FeeRow>
              <EmailRow direction={dir}>
                <SummaryField
                  id="walletAddress"
                  label={t('recipient')}
                  value={state.walletAddress}
                  dir={dir}
                />
                <SummaryField
                  id="emailAddress"
                  label={t('email')}
                  value={state.email}
                  dir={dir}
                />
              </EmailRow>
            </ContentTop>
          </WrapperTop>
          <WrapperBottom>
            <ContentBottom>
              <ConfirmationRow>
                <ConfirmationText>{t('confirmationCode')}</ConfirmationText>
                <VerificationCode
                  onComplete={handleComplete}
                  onCodeRefresh={handleCodeRefresh}
                  error={invalidPin}
                />
              </ConfirmationRow>
              <CenteredContent>
                <Button
                  data-test-id="button-confirm"
                  size={ButtonSize.Large}
                  onClick={handleConfirmConversion}
                  disabled={pinCode.length < PIN_CODE_LENGTH || isDisabled}
                  isLoading={isLoading}
                >
                  {t('button.confirmConversion')}
                </Button>
              </CenteredContent>
              <TermsText />
            </ContentBottom>
          </WrapperBottom>
        </>
      </Layout>
      <Modal
        ref={errorModal}
        type={ModalType.ERROR}
        onClose={handleModalClose}
      />
    </>
  );
};

export default TransactionSummary;

import styled from 'styled-components';

export const Wrapper = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  z-index: 2;
`;

export const InnerWrapper = styled.nav`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 22px 23px 24px;

  @media ${({ theme }) => theme.devices.desktop} {
    padding: 44px 40px 25px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    padding: 44px 67px 25px;
  }
`;

export const ImageWrapper = styled.div`
  width: 125px;
  height: 28px;
  cursor: pointer;

  @media ${({ theme }) => theme.devices.desktop} {
    width: 155px;
    height: 35px;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
`;

export const BlurBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    142.7deg,
    rgba(255, 255, 255, 0.6) 28.79%,
    rgba(250, 212, 0, 0.5) 103.55%
  );
  backdrop-filter: blur(7px);

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

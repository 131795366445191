/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../shared/text';
import {
  Wrapper,
  PoweredByWrapper,
  TextWrapper,
  LogoWrapper,
} from './price-info.styled';
import logoSmall from '../../../assets/images/logo-small.svg';
import usePriceELCASH from '../../../hooks/usePriceELCASH';

const PriceInfo = ({
  showAllInformation,
}: {
  showAllInformation?: boolean;
}) => {
  const { t } = useTranslation();
  const price = usePriceELCASH();

  return (
    <Wrapper showAllInformation={showAllInformation}>
      <LogoWrapper showAllInformation={showAllInformation}>
        <img src={logoSmall} width={22} height={22} alt="smallLogo" />
      </LogoWrapper>
      <TextWrapper showAllInformation={showAllInformation}>
        <Text fontSize={18} fontWeight="300">
          {t('currentPrice')}
        </Text>
        <Text
          margin="auto auto auto 6px"
          fontSize={18}
          fontSizeMobile={15}
          fontWeight="500"
        >
          {price} USD
        </Text>
        <PoweredByWrapper showAllInformation={showAllInformation}>
          <Text fontSize={11} fontWeight="500" lineHeight={12}>
            {t('poweredBy')}{' '}
            <a href="https://www.coingecko.com/en/coins/electric-cash">
              {t('coingecko')}
            </a>
          </Text>
        </PoweredByWrapper>
      </TextWrapper>
    </Wrapper>
  );
};

export default PriceInfo;

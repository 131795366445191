import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../shared/text';
import { MenuElementProps } from '../menu/menu.types';
import { LinksWrapper } from './mobile-menu.styled';

const MenuElementMobile = ({ url, name }: MenuElementProps) => {
  const { t } = useTranslation();

  return (
    <LinksWrapper>
      <Text fontWeight="300" fontSize={18} lineHeight={27} key={name}>
        <a href={url}>{t(name)}</a>
      </Text>
    </LinksWrapper>
  );
};

export default MenuElementMobile;

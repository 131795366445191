/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import NumberFormat from 'react-number-format';
import { round } from 'reliable-round';
import {
  FormValues,
  APISwapConditions,
  SwapDirection,
  ExtendedFormValues,
} from './interfaces';
import Input from '../shared/Input';
import {
  SwitcherRow,
  FeeRow,
  FeeValue,
  FeeCurrency,
  ConversionWrapper,
} from './styled';
import { ReactComponent as Switch } from '../../assets/images/switch.svg';
import { ExtraElement } from '../shared/Input/interfaces';
import {
  elcashToBscDirection,
  bscToElcashDirection,
  InputName,
} from './consts';
import useSwapConditions from '../../hooks/useSwapConditions';
import useLanguageDir from '../../hooks/useLanguageDir';

const DEFAULT_DECIMAL_SCALE = 8;
const DEFAULT_NUMBER_INTERVAL = 0.0001;
const SEND_INIT_VALUE = 0;
const MAX_INPUT_LENGTH = 20;

interface ConversionSectionProps {
  formik: FormikProps<FormValues>;
  onSwapConditionsSuccess: (
    exchangeFeeValue: string,
    networkFeeValue: string,
    maxExchangeAm: number,
    minConversionVal: number,
    swapDirection: SwapDirection
  ) => void;
  state: ExtendedFormValues;
}

const calculateInputValueMinusFees = (
  sendValue: number,
  exchangeFee: string,
  networkFee: string
): number => {
  const val =
    sendValue - sendValue * parseFloat(exchangeFee) - parseFloat(networkFee);

  return round(val, DEFAULT_DECIMAL_SCALE);
};

const ConversionSection: React.FC<ConversionSectionProps> = ({
  formik,
  onSwapConditionsSuccess,
  state,
}: ConversionSectionProps) => {
  const { t } = useTranslation();
  const dir = useLanguageDir();
  const [sendValue, setSendValue] = useState(SEND_INIT_VALUE);
  const [swapDirection, setSwapDirection] =
    useState<SwapDirection>(elcashToBscDirection);

  const fillConversionFields = (swap: APISwapConditions) => {
    formik.setFieldValue(InputName.SEND, sendValue);
    formik.setFieldValue(
      InputName.GET,
      calculateInputValueMinusFees(
        sendValue,
        swap.exchange_fee,
        swap.network_fee
      )
    );
  };

  const { data } = useSwapConditions(
    swapDirection.from,
    swapDirection.to,
    (swap: APISwapConditions) => {
      if (state !== undefined) {
        if (sendValue === SEND_INIT_VALUE) {
          formik.setFieldValue(InputName.SEND, parseInt(state?.send, 10));
          setSendValue(parseInt(state?.send, 10));
          formik.setFieldValue(
            InputName.GET,
            calculateInputValueMinusFees(
              parseInt(state?.send, 10),
              swap.exchange_fee,
              swap.network_fee
            )
          );
        } else {
          fillConversionFields(swap);
        }
      } else if (sendValue !== SEND_INIT_VALUE) {
        fillConversionFields(swap);
      }

      onSwapConditionsSuccess(
        swap.exchange_fee,
        swap.network_fee,
        round(parseFloat(swap.max_exchange_amount), DEFAULT_DECIMAL_SCALE),
        round(parseFloat(swap.min_exchange_amount), DEFAULT_DECIMAL_SCALE),
        swapDirection
      );
    }
  );

  const handleCalculateInputValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    swapData: APISwapConditions | undefined
  ) => {
    const inputValue = parseFloat(e.target.value);

    if (e.target.value.length > MAX_INPUT_LENGTH) {
      return false;
    }

    formik.setFieldValue(InputName.SEND, e.target.value);
    setSendValue(inputValue);
    if (!_.isNaN(inputValue)) {
      if (swapData) {
        const calculatedGetInputValue =
          inputValue -
          inputValue * parseFloat(swapData.exchange_fee) -
          parseFloat(swapData.network_fee);

        formik.setFieldValue(
          InputName.GET,
          round(calculatedGetInputValue, DEFAULT_DECIMAL_SCALE)
        );
      }
    } else {
      setSendValue(SEND_INIT_VALUE);
      formik.setFieldValue(InputName.GET, '');
    }
  };

  const handleConvertionSwitch = (): void => {
    if (_.isEqual(swapDirection, elcashToBscDirection)) {
      setSwapDirection(bscToElcashDirection);
    } else {
      setSwapDirection(elcashToBscDirection);
    }
  };

  return (
    <ConversionWrapper>
      <Input
        lang="en"
        id={InputName.SEND}
        data-test-id="send"
        type="number"
        step={DEFAULT_NUMBER_INTERVAL}
        min="0"
        maxLength={12}
        pattern="^[\.\,0-9]*$"
        inputMode="decimal"
        name={InputName.SEND}
        label="0"
        value={formik.values.send}
        onChange={(e) => handleCalculateInputValue(e, data)}
        onBlur={formik.handleBlur}
        swapDirection={swapDirection}
        conversion
        send
        extras={[
          [
            ExtraElement.Text,
            _.isEqual(swapDirection, elcashToBscDirection)
              ? t('elcash')
              : t('welcash'),
          ],
          [ExtraElement.Name, t('input.send')],
        ]}
        errors={
          formik.touched.send && formik.errors.send ? formik.errors.send : ''
        }
      />
      <FeeRow direction={dir}>
        {data && (
          <>
            <FeeValue>
              {t('exchangeFee')}
              {'\n'}
              <NumberFormat
                data-test-id="exchangeFee"
                value={sendValue * parseFloat(data.exchange_fee)}
                displayType="text"
                decimalScale={DEFAULT_DECIMAL_SCALE}
              />{' '}
              <FeeCurrency>ELCASH</FeeCurrency>
            </FeeValue>
            <FeeValue>
              {t('networkFee')}
              {'\n'}
              <NumberFormat
                data-test-id="networkFee"
                value={data.network_fee}
                displayType="text"
                decimalScale={DEFAULT_DECIMAL_SCALE}
              />{' '}
              <FeeCurrency>ELCASH</FeeCurrency>
            </FeeValue>
          </>
        )}
      </FeeRow>
      <SwitcherRow>
        <Switch onClick={handleConvertionSwitch} data-test-id="switcher" />
      </SwitcherRow>
      <Input
        id={InputName.GET}
        disabled
        data-test-id="get"
        type="number"
        name={InputName.GET}
        label="0"
        value={formik.values.get}
        swapDirection={swapDirection}
        conversion
        extras={[
          [
            ExtraElement.Text,
            _.isEqual(swapDirection, elcashToBscDirection)
              ? t('welcash')
              : t('elcash'),
          ],
          [ExtraElement.Name, t('input.get')],
        ]}
        errors={formik.errors.get ? formik.errors.get : ''}
      />
    </ConversionWrapper>
  );
};

export default ConversionSection;

import React from 'react';
import { useTranslation } from 'react-i18next';
import SocialMediaList from '../SocialMediaList';
import {
  Wrapper,
  InnerWrapper,
  FooterText,
  FooterTopContent,
  FooterBottomText,
  SocialMediaWrapper,
} from './styled';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InnerWrapper>
        <FooterTopContent>
          <FooterText>{t('joinUsOn')}</FooterText>
          <SocialMediaWrapper>
            <SocialMediaList />
          </SocialMediaWrapper>
        </FooterTopContent>
        <FooterBottomText>{t('copyright')}</FooterBottomText>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Footer;

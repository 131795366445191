import styled from 'styled-components';

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const PDFLink = styled.a`
  color: inherit;
  text-decoration: underline;
`;

export const WrapperTop = styled.div`
  background: linear-gradient(
    129.04deg,
    rgba(255, 255, 255, 0.7) 18.9%,
    rgba(253, 238, 156, 0.661151) 64.96%,
    rgba(250, 212, 0, 0.6) 104.94%
  );
  backdrop-filter: blur(10px);
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px -5px 24px -1px rgba(0, 0, 0, 0.1);
  padding-bottom: 18px;
  width: 721px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    width: 315px;
  }
`;

export const WrapperBottom = styled.div`
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 15px 15px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: -120px;
  padding-top: 34px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding-top: 18px;
    width: 315px;
  }
`;

export const ContentTop = styled.div`
  padding: 34px 36px 0px 36px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 18px 18px 0px 18px;
  }
`;

export const ContentBottom = styled.div`
  padding: 0px 36px 24px 36px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 0px 18px 20px 18px;
  }
`;

export enum Chain {
  ELCASH = 'ELCASH',
  BSC = 'BSC',
}

export enum InputName {
  SEND = 'send',
  GET = 'get',
}

export const bscToElcashDirection = {
  from: Chain.BSC,
  to: Chain.ELCASH,
};

export const elcashToBscDirection = {
  from: Chain.ELCASH,
  to: Chain.BSC,
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../shared/text';
import { MenuElementExpandableProps } from '../menu/menu.types';
import {
  ChevronWrapper,
  ExpandableWrapper,
  ExpandableWrapperInnerElement,
  ArrowWrapper,
} from './mobile-menu.styled';
import { ReactComponent as Arrow } from '../../../assets/images/yellow-arrow.svg';
import { ReactComponent as Expandable } from '../../../assets/images/expandable.svg';

const MenuElementMobileExpandable = ({
  name,
  url,
  elements,
}: MenuElementExpandableProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <ExpandableWrapper>
      <Text fontWeight="300" fontSize={18} lineHeight={27} key={name}>
        <a href={url}>{t(name)}</a>
        <ChevronWrapper onClick={() => setOpen(!open)} open={open}>
          <Expandable />
        </ChevronWrapper>
      </Text>
      {open &&
        elements.map((item) => {
          return (
            <ExpandableWrapperInnerElement key={name}>
              <Text fontWeight="300" fontSize={18} lineHeight={27}>
                <a href={item.url}>
                  <>
                    {t(item.name)}
                    <ArrowWrapper>
                      <Arrow />
                    </ArrowWrapper>
                  </>
                </a>
              </Text>
            </ExpandableWrapperInnerElement>
          );
        })}
    </ExpandableWrapper>
  );
};

export default MenuElementMobileExpandable;

import React from 'react';
import { round } from 'reliable-round';
import {
  ProgressWrapper,
  ProgressPercentage,
  CoinValueProgress,
  AmountReceived,
  AmountLeft,
} from './styled';

interface ProgressBarProps {
  value: number;
  max: number;
  width: number;
  amountReceived: string;
  amountRequested: string;
  chainSymbol: string;
}

const ProgressBar = ({
  value,
  max,
  width,
  amountReceived,
  amountRequested,
  chainSymbol,
}: ProgressBarProps): JSX.Element => {
  const DECIMAL_PRECISION = 8;
  const amountLeftToPay = (
    parseFloat(amountRequested) - parseFloat(amountReceived)
  ).toFixed(DECIMAL_PRECISION);

  return (
    <ProgressWrapper width={width}>
      <ProgressPercentage value={value}>{value}%</ProgressPercentage>
      <progress value={value} max={max} />
      <CoinValueProgress>
        <AmountReceived>
          {amountReceived} {chainSymbol}
        </AmountReceived>
        {parseFloat(amountLeftToPay) > 0 && (
          <AmountLeft>
            {amountLeftToPay} {chainSymbol}
          </AmountLeft>
        )}
      </CoinValueProgress>
    </ProgressWrapper>
  );
};

export default ProgressBar;

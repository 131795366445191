import React, { useEffect } from 'react';
import * as _ from 'lodash';
import { elcashToBscDirection } from '../../ELCASHConverter/consts';
import {
  StyledInput,
  InputContainer,
  ExtraElementContainer,
  ErrorContainer,
} from './styled';
import { ExtraElement, InputProps } from './interfaces';

const Input: React.FC<InputProps> = ({
  id,
  type,
  name,
  label,
  value,
  disabled,
  onChange,
  onBlur,
  extras,
  errors,
  send = false,
  dir = 'ltr',
  conversion = false,
  swapDirection = elcashToBscDirection,
  ...rest
}: InputProps) => {
  const renderExtraElements = (
    extraEl: [ExtraElement, string][]
  ): React.ReactNode => {
    if (extraEl.length) {
      return extraEl.map((extraElement: [ExtraElement, string]) => {
        switch (extraElement[0]) {
          case ExtraElement.Text:
            return (
              <>
                <ExtraElementContainer
                  direction={dir}
                  type={ExtraElement.Text}
                  errors={errors}
                  name={name}
                  data-test-id={`${extraElement[0]}-${extraElement[1]}`}
                >
                  {extraElement[1]}
                </ExtraElementContainer>
              </>
            );
          case ExtraElement.Name:
            return (
              <>
                <ExtraElementContainer direction={dir} type={ExtraElement.Name}>
                  {extraElement[1]}
                </ExtraElementContainer>
              </>
            );
          default:
            return null;
        }
      });
    }
    return null;
  };

  return (
    <>
      <InputContainer>
        <StyledInput
          id={id}
          type={type}
          value={value}
          name={name}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={label}
          errors={errors}
          dir={dir}
          swapDirection={swapDirection}
          conversion={conversion}
          {...rest}
        />
        {extras && renderExtraElements(extras)}
        <ErrorContainer
          data-test-id={`error-${id}`}
          direction={dir}
          send={send}
          conversion={conversion}
        >
          {errors}
        </ErrorContainer>
      </InputContainer>
    </>
  );
};

Input.defaultProps = {
  disabled: false,
  onBlur: () => {},
  extras: [],
};

export default Input;

import styled from 'styled-components';

export const Overlay = styled.div<{ modalOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: ${({ modalOpen }) => (modalOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${({ theme }) => `border-radius: ${theme.borderRadius}`};
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Headline = styled.div<{ direction: string }>`
  margin-bottom: 20px;

  ${({ direction }) =>
    direction === 'rtl' ? 'text-align: right;' : 'text-align: left;'};
  ${({ theme }) => `font-size: ${theme.fontSize.mobileTitleBig}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.middle}`};
    ${({ direction }) => (direction === 'rtl' ? '' : 'max-width: 279px')};

    ${({ direction }) =>
      direction === 'rtl' ? 'text-align: right;' : 'text-align: left;'};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
`;

export const FeeRow = styled.div<{ direction: string }>`
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px 20px 20px 20px;
  z-index: 1;
  position: relative;
  margin-top: -10px;
  width: 648px;

  ${({ theme }) => `font-size: ${theme.fontSize.middle};
  background-color: ${theme.colors.white}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    white-space: break-spaces;
    width: 279px;
    padding: 5px 12px 15px 12px;
    ${({ theme }) => `font-size: ${theme.fontSize.label};`}
    ${({ direction }) =>
      direction === 'rtl' ? 'text-align: right;' : 'text-align: left;'};
  }
`;

export const SwitcherRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 8px 0px;
  padding-right: 27px;
  cursor: pointer;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding-right: 22px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  > * {
    &:first-child {
      margin-bottom: 35px;
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 30px;
    > * {
      &:first-child {
        margin-bottom: 25px;
      }
    }
  }
`;

export const ConversionRatio = styled.span`
  ${({ theme }) => `color: ${theme.colors.secondary}`}
`;

export const PDFLink = styled.a`
  color: inherit;
  text-decoration: underline;
`;

export const PrivacyPolicy = styled.span`
  display: block;
`;

export const FeeValue = styled.div`
  ${({ theme }) => `color: ${theme.colors.darkGray}`}
`;

export const FeeCurrency = styled.span`
  ${({ theme }) => `font-size: ${theme.fontSize.regular}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.small}`};
  }
`;

export const SendText = styled.p`
  ${({ theme }) => `font-size: ${theme.fontSize.mobileTitleBig}`};
  margin-bottom: 24px;
  padding-left: 7px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.title}`};
    margin-bottom: 12px;
  }
`;

export const ConversionWrapper = styled.div`
  margin-bottom: 26px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

/* eslint-disable @typescript-eslint/no-shadow */
import { useTranslation } from 'react-i18next';
import React from 'react';
import Text from '../../shared/text';
import {
  ExpandableInnerElement,
  ExpandableInnerWrapper,
  ExpandableWrapper,
  LinkElementExpandable,
  LinksElement,
  YellowArrowWrapper,
  ArrowWrapper,
} from './menu.styled';
import { MenuElementExpandableProps } from './menu.types';
import { ReactComponent as Expandable } from '../../../assets/images/expandable.svg';
import { ReactComponent as Arrow } from '../../../assets/images/yellow-arrow.svg';

const MenuElementExpandable = ({
  name,
  url,
  elements,
}: MenuElementExpandableProps) => {
  const { t } = useTranslation();

  return (
    <LinksElement>
      <a href={url}>
        <LinkElementExpandable>
          <Text fontSize={18} fontWeight="300">
            {t(name)}
          </Text>
          <ArrowWrapper>
            <Expandable />
          </ArrowWrapper>
        </LinkElementExpandable>
      </a>
      <ExpandableWrapper>
        <ExpandableInnerWrapper>
          {elements.map(({ name, url }) => (
            <a href={url} key={name} target="_blank" rel="noopener noreferrer">
              <ExpandableInnerElement>
                <Text fontSize={16} fontWeight="300">
                  {t(name)}
                </Text>
                <YellowArrowWrapper>
                  <Arrow />
                </YellowArrowWrapper>
              </ExpandableInnerElement>
            </a>
          ))}
        </ExpandableInnerWrapper>
      </ExpandableWrapper>
    </LinksElement>
  );
};

export default MenuElementExpandable;

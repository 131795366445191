/* eslint-disable import/no-cycle */
import React, {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
  Ref,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { ReactComponent as Close } from '../../../assets/images/modal-close.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/images/error.svg';
import { ModalRef, ModalProps, ModalType } from './interfaces';
import {
  Overlay,
  CloseButton,
  ModalContainer,
  ModalHeadline,
  IconWrapper,
  Headline,
  Message,
  HomeButton,
} from './styled';

const Modal = (
  { children, defaultOpened = false, headline, type, onClose }: ModalProps,
  ref: Ref<ModalRef>
) => {
  const [isOpen, setIsOpen] = useState(defaultOpened);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const close = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      openErrorMessage: (errorMessage: string) => {
        setMessage(errorMessage);
        setIsOpen(true);
      },
      close,
    }),
    [close]
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        close();
      }
    },
    [close]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleEscape, false);
    }
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  const renderModal = (modalType: ModalType) => {
    if (modalType === ModalType.INFO) {
      return (
        <Overlay>
          <ModalContainer type={type}>
            <CloseButton onClick={close}>
              <Close />
            </CloseButton>
            <ModalHeadline>{headline}</ModalHeadline>
            {children}
          </ModalContainer>
        </Overlay>
      );
    }
    if (modalType === ModalType.ERROR) {
      return (
        <Overlay>
          <ModalContainer type={type}>
            <Headline>{t('error')}</Headline>
            <Message> {message} </Message>
            <IconWrapper>
              <ErrorIcon />
            </IconWrapper>
            <HomeButton onClick={close}>{t('backHome')}</HomeButton>
          </ModalContainer>
        </Overlay>
      );
    }
    return null;
  };

  return createPortal(
    isOpen ? renderModal(type) : null,
    document.getElementById('modal-root') as HTMLElement
  );
};

export default forwardRef(Modal);

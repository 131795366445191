/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div<{ open: boolean }>`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  transition: all 0.2s ease-in-out;
  z-index: 10;
  user-select: none;

  :before,
  :after,
  span {
    user-select: none;
    display: block;
    transition: all 0.2s ease-in-out;
    content: '';
    height: 2px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.black};
  }

  span {
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: ${({ theme }) => theme.colors.black};
  }

  ${({ open }) =>
    open &&
    `
    span {
      transform: scale(0);
    }
    :before {
      transform: translateY(10px) rotate(45deg);
    }
    :after {
      transform: translateY(-10px) rotate(-45deg);
    }
  `}

  @media ${({ theme }) => theme.devices.desktop} {
    display: none;
  }
`;

/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { TextProps } from './interfaces';

export const TextContent = styled.div<TextProps>`
  font-family: Ubuntu;
  font-style: ${({ fontStyle }) => fontStyle || 'normal'};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: ${({ fontSizeMobile, fontSize }) =>
    fontSizeMobile || fontSize || 16}px;
  line-height: ${({ lineHeight }) => lineHeight || 16}px;
  text-align: ${({ align }) => align || 'initial'};
  color: ${({ color, theme }) => color || theme.colors.black};
  margin: ${({ margin }) => margin || 'auto'};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'initial')};
  // text-wrap: pre;

  @media ${({ theme }) => theme.devices.desktop} {
    font-size: ${({ fontSize }) => fontSize || 16}px;
  }
`;

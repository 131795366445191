import styled from 'styled-components';
import { ReactComponent as Checked } from '../../../assets/images/checkbox-ok.svg';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const CheckedIcon = styled(Checked)`
  position: absolute;
  top: 4px;
  left: 3px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<{ checked: boolean; error: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => props.checked && props.theme.colors.black};
  border: ${(props) => {
    if (props.error) {
      return `1px solid ${props.theme.colors.red}`;
    }
    if (!props.checked) {
      return `1px solid ${props.theme.colors.darkGray}`;
    }
    return 'none';
  }};
  transition: all 150ms;
  position: relative;
  border-radius: 3px;
`;

export const Label = styled.span<{ error: boolean }>`
  margin-left: 10px;
  font-weight: 300;
  color: ${(props) => props.error && props.theme.colors.red};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.label}`};
  }
`;

export const LabelWrapper = styled.label`
  margin-top: 40px;
  display: flex;
`;

import { useQuery, UseQueryResult, setLogger } from 'react-query';
import axios from 'axios';
import { APISwapConditions } from '../components/ELCASHConverter/interfaces';
import { Config } from '../Config';

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

export const getSwapConditions = async (
  chainIn: string,
  chainOut: string
): Promise<APISwapConditions> => {
  const { data } = await axios.get(
    `${Config.ApiUrl}/swap/conditions?chain_in=${chainIn}&chain_out=${chainOut}`
  );

  return data;
};

export default function useSwapConditions(
  chainIn: string,
  chainOut: string,
  success: (swapData: APISwapConditions) => void
): UseQueryResult<APISwapConditions> {
  return useQuery(
    ['swapconditions', chainIn, chainOut],
    () => getSwapConditions(chainIn, chainOut),
    {
      onSuccess: (data: APISwapConditions) => {
        success(data);
      },
    }
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CodeRefresh, VerificationCodeWrapper, ErrorWrapper } from './styled';
import CodeInput from './CodeInput';
import useInterval from './hooks';

interface VerificationCodeProps {
  onComplete: (code: string) => void;
  onCodeRefresh: () => void;
  error: string;
}

const VerificationCode: React.FC<VerificationCodeProps> = ({
  onComplete,
  onCodeRefresh,
  error,
}: VerificationCodeProps) => {
  const { t } = useTranslation();
  const REFRESH_CODE_TIMEOUT = 30;
  const [count, setCount] = useState<number>(REFRESH_CODE_TIMEOUT);
  const [isRefreshing, setRefresh] = useState(false);

  useInterval(
    () => {
      if (count > 0) {
        setCount(count - 1);
      }
      if (count === 0) {
        setCount(REFRESH_CODE_TIMEOUT);
        setRefresh(false);
      }
    },
    isRefreshing ? 1000 : null
  );

  const handleCodeRefresh = (): void => {
    if (!isRefreshing) {
      setRefresh(true);
      onCodeRefresh();
    }
  };

  return (
    <VerificationCodeWrapper>
      <CodeInput
        length={6}
        onComplete={onComplete}
        isRefreshing={isRefreshing}
        error={error}
      />
      {error && (
        <ErrorWrapper>
          {t('incorrentConfirmation')} {error}
        </ErrorWrapper>
      )}
      <CodeRefresh onClick={handleCodeRefresh} data-test-id="refresh-code">
        {isRefreshing ? <>{count}s</> : <>{t('resend')}</>}
      </CodeRefresh>
    </VerificationCodeWrapper>
  );
};

export default VerificationCode;

import styled from 'styled-components';
import Button from '../shared/button';

export const Overlay = styled.div<{ modalOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: ${({ modalOpen }) => (modalOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${({ theme }) => `border-radius: ${theme.borderRadius}`};
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;

  ${({ theme }) =>
    `font-size: ${theme.fontSize.mobileTitleBig}; font-weight: ${theme.fontWeight.bold}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    max-width: 180px;
    margin-bottom: 4px;

    ${({ theme }) => `font-size: ${theme.fontSize.middle};`};
  }
`;

export const TransactionIdRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    flex-direction: column;
  }

  ${({ theme }) => `font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWight.light}`};
`;

export const TransactionIdDesc = styled.div`
  max-width: 597px;
  margin-bottom: 20px;
  ${({ theme }) => `color: ${theme.colors.darkGray};
  font-size: ${theme.fontSize.regular};
  font-weight: ${theme.fontWeight.bold}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    max-width: 280px;
  }
`;

export const CopyRow = styled.span`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  cursor: pointer;
  margin-top: 8px;
  ${({ theme }) => `color: ${theme.colors.darkPrimary};
  font-size: ${theme.fontSize.medium}; 
  font-weight: ${theme.fontWeight.extraBold}`};

  &:hover {
    opacity: 0.8;
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 20px;
    margin-top: 4px;
    ${({ theme }) => `font-size: ${theme.fontSize.regular}`};
  }
`;

export const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 25px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

export const TimerRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Timer = styled.div<{ isTimeout: boolean }>`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  text-align: center;
  ${({ theme }) => `font-size: ${theme.fontSize.large}`};

  ${(props) => props.isTimeout && `color: ${props.theme.colors.red}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 10px;
  }
`;

export const TimerDesc = styled.div`
  padding: 0px 35px;
  text-align: center;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 24px;
  max-width: 600px;
  ${({ theme }) => `color: ${theme.colors.darkGray};
  font-size: ${theme.fontSize.regular};
  font-weight: ${theme.fontWeight.bold}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 0;
    max-width: 280px;
  }
`;

export const WalletRow = styled.div`
  > * {
    &:nth-child(0) {
      margin-bottom: -20px;
    }
    &:nth-child(1) {
      margin-bottom: -20px;
    }
    &:nth-child(2) {
      margin-bottom: -20px;
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    word-break: break-word;
    text-align: center;
  }
`;

export const TransactionRow = styled.div`
  margin-bottom: 15px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 8px;
  }
`;

export const TimeoutMessage = styled.div<{ isTimeout: boolean }>`
  margin-bottom: 4px;
  ${({ theme }) => `font-size: ${theme.fontSize.mobileTitleBig};
  font-weight: ${theme.fontWeight.bold}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.middle};`};
  }
`;

export const TransactionId = styled.div`
  padding-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `font-weight: ${theme.fontWeight.bold};`};
`;

export const ProgressWrapper = styled.div<{ width: number }>`
  ${({ theme }) => `background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius}`};
  padding: 10px 25px 20px 25px;
  progress[value] {
    width: 597px;
    appearance: none;
    border: none;
    height: 8px;
    background: #f6f6f6;
    border-radius: 9px;

    ::-webkit-progress-value {
      height: 8px;
      border-radius: 21px;
      ${({ theme }) => `background: ${theme.colors.green};`};
    }

    ::-moz-progress-bar {
      height: 8px;
      border-radius: 21px;
      ${({ theme }) => `background: ${theme.colors.green};`};
    }

    ::-webkit-progress-bar {
      height: 8px;
      border-radius: 21px;
      ${({ theme }) => `background: ${theme.colors.lightGray};`};
    }

    @media ${({ theme }) => `${theme.device.xs}`} {
      width: 255px;
      height: 10px;
      progress[value] {
        width: 255px;
        height: 10px;

        ::-webkit-progress-value {
          height: 10px;
          border-radius: 21px;
          ${({ theme }) => `background: ${theme.colors.green};`};
        }

        ::-moz-progress-bar {
          height: 10px;
          border-radius: 21px;
          ${({ theme }) => `background: ${theme.colors.lightGray};`};
        }

        ::-webkit-progress-bar {
          height: 10px;
          border-radius: 21px;
          ${({ theme }) => `background: ${theme.colors.lightGray};`};
        }
      }
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 12px;
  }
`;

export const ProgressPercentage = styled.div<{ value: number }>`
  ${({ theme }) =>
    `font-size: ${theme.fontSize.middle};  color: ${theme.colors.green}`};
  ${({ value }) => {
    if (value > 100) {
      return 'margin-left: 552px;';
    }
    if (value > 9) {
      return `margin-left: ${597 * (value / 100) - 32}px;`;
    }
    return `margin-left: ${597 * (value / 100) - 22}px;`;
  }}
  font-weight: bold;

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.label};`};

    ${({ value }) => {
      if (value > 100) {
        return 'margin-left: 225px;';
      }
      if (value > 9) {
        return `margin-left: ${255 * (value / 100) - 24}px;`;
      }
      return `margin-left: ${255 * (value / 100) - 20}px;`;
    }}
  }
`;

export const TimeSlot = styled.p`
  border-radius: 12px;
  padding: 14px;
  flex: 1 0 44px;

  ${({ theme }) =>
    `font-size: ${theme.fontSize.title};
     background-color: ${theme.colors.lightGray}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    flex: 1 0 36px;
    padding: 12px;
    ${({ theme }) => `font-size: ${theme.fontSize.middle};`};
  }
`;

export const TimeBlock = styled.div`
  display: flex;
  max-width: 92px;
  flex-wrap: wrap;

  > * {
    &:first-child {
      margin-right: 4px;
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    max-width: 76px;
  }
`;
export const TimeName = styled.p`
  flex-basis: 100%;
  text-align: center;
  margin-top: 4px;

  ${({ theme }) => `font-size: ${theme.fontSize.label};
  color: ${theme.colors.black}`};
`;

export const Separator = styled.span`
  padding-bottom: 20px;
  padding-left: 8px;
  padding-right: 8px;
  ${({ theme }) => `color: ${theme.colors.black}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const CoinValueProgress = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 597px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    max-width: 255px;

    > * {
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
`;

export const AmountLeft = styled.span`
  ${({ theme }) => `font-size: ${theme.fontSize.regular};
  color: ${theme.colors.darkGray}; 
  font-weight: ${theme.fontWeight.bold}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.label};`};
  }
`;
export const AmountReceived = styled.span`
  ${({ theme }) => `font-size: ${theme.fontSize.regular};
  color: ${theme.colors.green}; 
  font-weight: ${theme.fontWeight.bold}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.label};`};
  }
`;

export const ProgressRow = styled.div`
  margin-bottom: 15px;

  > * {
    &:nth-child(0) {
      margin-bottom: -20px;
    }
    &:nth-child(1) {
      margin-bottom: -20px;
    }
  }

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-bottom: 8px;
  }
`;

export const SuccessScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TransactionLink = styled.a`
  ${({ theme }) => `color: ${theme.colors.primary};`}
  word-wrap: break-word;
`;

export const HomeButton = styled(Button)`
  margin: 0px;
  padding: 14px 43px;
  width: 200px;
  letter-spacing: normal;

  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 14px 43px;
  }
`;

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../Layout';
import { Wrapper, Message, IconWrapper, Headline, HomeButton } from './styled';
import { ReactComponent as ErrorIcon } from '../../../assets/images/error.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/images/success.svg';
import { MessageType } from '../consts';

interface StateParams {
  messageType: MessageType;
  message: string;
}

const MessageWindow = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<StateParams>();

  const handleBackHome = (): void => {
    history.push('/');
  };

  return (
    <Layout>
      <Wrapper>
        <Headline>
          {state.messageType === MessageType.ERROR ? t('error') : t('success')}
        </Headline>
        <IconWrapper>
          {state.messageType === MessageType.ERROR ? (
            <ErrorIcon />
          ) : (
            <SuccessIcon />
          )}
        </IconWrapper>
        <Message>
          {' '}
          {state.messageType === MessageType.ERROR
            ? state.message
            : t(state.message)}{' '}
        </Message>
        <HomeButton onClick={handleBackHome}>{t('backHome')}</HomeButton>
      </Wrapper>
    </Layout>
  );
};

export default MessageWindow;

import React from 'react';
import MenuElementExpandable from './menu-element-expandable';
import MenuElement from './menu-element';
import { LinksWrapper } from './menu.styled';
import { MENU } from '../consts';

export default function Menu() {
  return (
    <LinksWrapper>
      {MENU.map((item) => {
        if (item.elements) {
          return (
            <MenuElementExpandable
              key={item.name}
              url={item.url}
              name={item.name}
              elements={item.elements}
            />
          );
        }
        return <MenuElement key={item.name} url={item.url} name={item.name} />;
      })}
    </LinksWrapper>
  );
}

import React, { useState } from 'react';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { FormValues } from '../../ELCASHConverter/interfaces';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Label,
  LabelWrapper,
  CheckedIcon,
} from './styled';

interface CheckboxProps {
  checked: boolean;
  labelWrap?: boolean;
  label: React.ReactNode;
  formik: FormikProps<FormValues>;
  form: any;
  field: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  labelWrap = true,
  label,
  formik,
  field,
  form: { errors },
  ...props
}) => {
  const [isTouched, setTouched] = useState<boolean>(false);
  const handleAgree = () => {
    formik.setFieldValue('checkbox', !formik.values.checkbox);
    setTouched(true);
  };

  const content = (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox
        checked={checked}
        onClick={handleAgree}
        data-test-id="checkbox"
        error={isTouched && errors[field.name]}
      >
        {checked && <CheckedIcon />}
      </StyledCheckbox>
    </CheckboxContainer>
  );

  return labelWrap ? (
    <LabelWrapper>
      {content}
      <Label error={isTouched && errors[field.name]}>{label}</Label>
    </LabelWrapper>
  ) : (
    <>{content}</>
  );
  return content;
};

export default Checkbox;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { string } from 'yup';
import {
  FieldWrapper,
  FieldLabel,
  FieldValue,
  FieldDescription,
  ExtraWrapper,
} from './styled';

interface SummaryFieldProps {
  label: string;
  value: string;
  limitedWidth?: boolean;
  description?: string;
  id?: string;
  extra?: React.ReactNode;
  dir?: string;
}

const SummaryField: React.FC<SummaryFieldProps> = ({
  label,
  value,
  limitedWidth = false,
  description,
  id,
  extra = undefined,
  dir = 'ltr',
}: SummaryFieldProps) => {
  // @ts-ignore
  const hasExtra = extra.props.children;

  return (
    <>
      <FieldWrapper hasExtra={hasExtra}>
        <FieldLabel direction={dir}>{label}</FieldLabel>
        <FieldValue
          data-test-id={id}
          direction={dir}
          limitedWidth={limitedWidth}
        >
          {value}
        </FieldValue>
        {description && (
          <FieldDescription direction={dir}>
            {description && description}
          </FieldDescription>
        )}
        {hasExtra && <ExtraWrapper>{extra}</ExtraWrapper>}
      </FieldWrapper>
    </>
  );
};

SummaryField.defaultProps = {
  description: '',
  id: '',
  limitedWidth: false,
  extra: <></>,
  dir: 'ltr',
};

export default SummaryField;

export enum Routes {
  Converter = '/',
  Summary = '/summary',
  CompleteTransaction = '/transaction',
  Error = '/error',
}

export enum MessageType {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum ButtonSize {
  Normal = 'normal',
  Large = 'large',
  Xlarge = 'xlarge',
}

export enum FeedbackStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const SOCIAL_MEDIA_LINKS = {
  twitter: {
    name: 'twitter',
    url: 'https://twitter.com/elcash_official',
  },
  telegram: {
    name: 'telegram',
    url: 'https://t.me/elcash_official',
  },
  medium: {
    name: 'medium',
    url: 'https://medium.com/electric-cash',
  },
  facebook: {
    name: 'facebook',
    url: 'https://www.facebook.com/electriccash.official',
  },
  instagram: {
    name: 'instagram',
    url: 'https://www.instagram.com/electriccash.official',
  },
  youTube: {
    name: 'youTube',
    url: 'https://www.youtube.com/channel/UCwH6idQAvAQG69uiZ4Mod8w',
  },
  github: {
    name: 'github',
    url: 'https://github.com/electric-cash',
  },
};

export const RTL_LANGUAGES = ['ar'];

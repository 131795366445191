import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 62px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-top: 63px;
  padding-bottom: 63px;
  z-index: 5;
  overflow: scroll;

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const ExpandableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 42px;
`;

export const ExpandableWrapperInnerElement = styled.div`
  margin-top: 6px;
  text-transform: capitalize;
`;

export const LinksWrapper = styled.div`
  display: block;
  margin: 0 auto;
  margin-bottom: 42px;
`;

export const ChevronWrapper = styled.div<{ open: boolean }>`
  display: inline-block;
  padding-left: 8px;
  vertical-align: text-bottom;
  ${({ open }) =>
    open &&
    css`
      div {
        transform: rotate(180deg);
      }
    `}
`;

export const ArrowWrapper = styled.span`
  margin-left: 8px;
`;

import React from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import useIsMobile from '../../../hooks/useIsMobile';
import Footer from '../../Footer';
import { ReactComponent as SmallCircleDesktop } from '../../../assets/images/dot1.svg';
import { ReactComponent as ElcashBgLogoDesktop } from '../../../assets/images/elcash-bg-logo.svg';
import { ReactComponent as SmallCircleMobile } from '../../../assets/images/dot3.svg';
import { ReactComponent as ElcashBgLogoMobile } from '../../../assets/images/elcash-bg-logo-mobile.svg';
import { ReactComponent as BscIcon } from '../../../assets/images/smart-contract.svg';
import { Config } from '../../../Config';
import Header from '../../Header';

const Main = styled.main`
  flex: 1;
  margin-top: 50px;

  @media ${theme.device.xs} {
    margin-top: 30px;
  }
`;

const Wp = styled.div`
  display: flex;
  justify-content: center;
  /* position: relative; */
`;

export const Wrapper = styled.div`
  background: #f1f1f1;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
`;

const SectionsWrapper = styled.div`
  z-index: 1;
  position: relative;
`;

const BscIconContainer = styled.div`
  @media ${theme.device.xs} {
    position: absolute;
    top: -30px;
    padding-bottom: 30px;
  }
`;

const BscAddress = styled.div`
  text-overflow: ellipsis;
  margin-left: 10px;
  padding-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  text-decoration-line: underline;
  line-height: 19px;
  color: ${theme.colors.primary};

  a {
    color: ${theme.colors.primary};
  }

  @media ${theme.device.xs} {
    width: 300px;
    position: absolute;
    top: -30px;
    left: 10px;
    font-size: ${theme.fontSize.label};
    text-overflow: initial;
  }
`;

const AddressWrapper = styled.div`
  margin-bottom: 7px;
  text-align: center;
  display: flex;
  justify-content: left;

  @media ${theme.device.xs} {
    margin: 25px 0px;
    font-size: ${theme.fontSize.regular};
  }
`;

const Shape = styled.div`
  position: relative;
  overflow: hidden;
  height: 250px;

  &:before {
    border-radius: 100%;
    position: absolute;
    background: ${theme.colors.primary};
    right: -200px;
    left: -200px;
    top: 0px;
    content: '';
    bottom: -200px;
  }

  @media ${theme.device.xs} {
    height: 150px;
  }
`;

const FirstDot = styled.div`
  position: absolute;
  top: 200px;
  left: -30px;
  z-index: -1;

  @media ${theme.device.xs} {
    left: -5px;
    top: 260px;
  }
`;

const ElcashBgLogo = styled.div`
  position: absolute;
  top: 0px;
  left: 572px;
  z-index: -1;

  @media ${theme.device.xs} {
    left: 230px;
    top: 20px;
  }
`;

type LayoutProps = {
  children: JSX.Element;
};

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const isMobile = useIsMobile(theme.deviceSize.mobile);

  return (
    <Wrapper>
      <Header />
      <Main>
        <Wp>
          <SectionsWrapper>
            <AddressWrapper>
              <BscIconContainer>
                <BscIcon />
              </BscIconContainer>
              <BscAddress>
                <a href={`${Config.bscscanUrl}/token/${Config.bscAddress}`}>
                  {Config.bscAddress}
                </a>
              </BscAddress>
            </AddressWrapper>
            <FirstDot>
              {isMobile ? <SmallCircleMobile /> : <SmallCircleDesktop />}
            </FirstDot>
            <ElcashBgLogo>
              {isMobile ? <ElcashBgLogoMobile /> : <ElcashBgLogoDesktop />}
            </ElcashBgLogo>
            {children}
          </SectionsWrapper>
        </Wp>
        <Shape />
      </Main>
      <Footer />
    </Wrapper>
  );
};

export default Layout;

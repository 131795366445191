export interface SwapErrorResponse {
  result: string;
  error_code: number;
  msg: string;
}

interface ErrorResponse {
  data: SwapErrorResponse;
  status: number;
  statusText: string;
}

export interface SwapError {
  response: ErrorResponse;
}

export enum ErrorType {
  INVALID_PIN = 5,
}

import { InputHTMLAttributes } from 'react';
import { FormikErrors } from 'formik';
import { SwapDirection } from '../../ELCASHConverter/interfaces';

export enum ExtraElement {
  Tooltip = 'tooltip',
  Text = 'text',
  Name = 'name',
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type:
    | 'button'
    | 'checkbox'
    | 'email'
    | 'hidden'
    | 'number'
    | 'password'
    | 'submit'
    | 'text';
  id: string;
  testid?: string;
  name: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  extras?: [ExtraElement, string][];
  errors?: FormikErrors<string>;
  dir?: string;
  swapDirection?: SwapDirection;
  conversion?: boolean;
  send?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

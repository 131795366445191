/* eslint-disable @typescript-eslint/ban-ts-comment */
import { address } from 'bitcoinjs-lib';
import { TimeLeft } from '../components/CompleteTransaction/interfaces';
import { Config } from '../Config';
import { elcashNetworkMainnet, elcashNetworkTestnet } from '../constants';

export const getDateDifference = (from: Date, to: Date): TimeLeft => {
  const unixFrom = Math.floor(new Date(from).getTime());
  const unixTo = Math.floor(new Date(to).getTime());

  if (unixFrom > unixTo) {
    return {
      hour: 0,
      minute: 0,
      second: 0,
    };
  }

  let delta = Math.abs(unixTo - unixFrom) / 1000;
  const result: TimeLeft = {
    hour: 0,
    minute: 0,
    second: 0,
  };
  const s = {
    hour: 3600,
    minute: 60,
    second: 1,
  };

  (Object.keys(s) as Array<keyof typeof s>).forEach((key) => {
    result[key as keyof TimeLeft] = Math.floor(delta / s[key]);
    delta -= result[key as keyof TimeLeft] * s[key];
  });

  return result;
};

export const checkELCASHaddress = (walletAddress: string) => {
  if (Config.elcashNetwork === 'elcashNetworkTestnet') {
    address.toOutputScript(walletAddress, elcashNetworkTestnet);
  } else {
    address.toOutputScript(walletAddress, elcashNetworkMainnet);
  }
};

export const isELCASHAddressValid = (
  walletAddress: string | undefined
): boolean => {
  if (walletAddress) {
    try {
      checkELCASHaddress(walletAddress);

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  }
  return false;
};

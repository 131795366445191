/* eslint no-nested-ternary: 0 */
import styled from 'styled-components';
import {
  bscToElcashDirection,
  elcashToBscDirection,
  InputName,
} from '../../ELCASHConverter/consts';
import { SwapDirection } from '../../ELCASHConverter/interfaces';
import { ExtraElement } from './interfaces';

export const StyledInput = styled.input<{
  errors?: string;
  dir?: string;
  swapDirection?: SwapDirection;
  name?: string;
  conversion?: boolean;
}>`
  width: 648px;
  ${({ conversion }) =>
    conversion ? `text-align: right;` : `text-align: left;`}
  ${({ swapDirection, name }) => {
    if (swapDirection === elcashToBscDirection && name === InputName.SEND) {
      return 'padding: 20px 80px 20px 26px;';
    }
    if (swapDirection === elcashToBscDirection && name === InputName.GET) {
      return 'padding: 20px 93px 20px 26px;';
    }
    if (swapDirection === bscToElcashDirection && name === InputName.SEND) {
      return 'padding: 20px 93px 20px 26px;';
    }
    if (swapDirection === bscToElcashDirection && name === InputName.GET) {
      return 'padding: 20px 80px 20px 26px;';
    }

    return 'padding: 20px 26px 20px 26px;';
  }}

  ${({ theme }) =>
    `border-radius: ${theme.borderRadius}; color: ${theme.colors.black}`}
  position: relative;

  ::placeholder {
    line-height: 24px;
    ${({ theme, conversion }) =>
      conversion
        ? `color: ${theme.colors.black};`
        : `color: ${theme.colors.darkGray};`}

    ${({ theme }) => `font-size: ${theme.fontSize.title};`}

    @media ${({ theme }) => `${theme.device.xs}`} {
      ${({ theme }) => `font-size: ${theme.fontSize.regular}`};
    }
  }

  ${({ theme, conversion }) =>
    conversion
      ? `background-color: ${theme.colors.white};`
      : `background-color: ${theme.colors.lightGray};`};

  ${({ theme }) => `font-size: ${theme.fontSize.title};
  border: 1px solid ${theme.colors.white}`};

  ${(props) => props?.errors?.length && `color: ${props.theme.colors.red};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    width: 279px;
    height: 45px;
    padding: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${({ theme }) => `font-size: ${theme.fontSize.label}`};

    ${({ swapDirection, name }) => {
      if (swapDirection === elcashToBscDirection && name === InputName.SEND) {
        return 'padding: 12px 62px 12px 12px;';
      }
      if (swapDirection === elcashToBscDirection && name === InputName.GET) {
        return 'padding: 12px 72px 12px 12px;';
      }
      if (swapDirection === bscToElcashDirection && name === InputName.SEND) {
        return 'padding: 12px 72px 12px 12px;';
      }
      if (swapDirection === bscToElcashDirection && name === InputName.GET) {
        return 'padding: 12px 62px 12px 12px;';
      }

      return 'padding: 12px;';
    }}
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 648px;

  @media ${({ theme }) => `${theme.device.xs}`} {
    width: 279px;
  }
`;

export const InputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExtraElementContainer = styled.div<{
  direction: string;
  type: string;
  errors?: string;
  name?: string;
}>`
  ${({ type, theme }) =>
    type === ExtraElement.Name
      ? `left: 20px; font-size: ${theme.fontSize.title}; top: 23px;`
      : 'right: 20px; top: 25px;'}
  ${({ name, errors, theme }) =>
    errors?.length && name === InputName.GET && `color: ${theme.colors.red};`};
  position: absolute;
  font-weight: 500;

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ type, theme }) =>
      type === ExtraElement.Name
        ? `left: 12px; font-size: ${theme.fontSize.regular}; top: 15px;`
        : `right: 12px; top: 16px; font-size: 13px;`}
  }
`;

export const ErrorContainer = styled.div<{
  direction: string;
  conversion: boolean;
  send: boolean;
}>`
  position: absolute;
  ${({ send }) =>
    send ? 'bottom: -60px;' : !send ? 'bottom: -25px' : 'bottom: -25px;'};
  ${({ direction, conversion }) =>
    direction === 'rtl' && !conversion ? 'right: 0px;' : 'left: 0px;'};

  ${({ theme }) =>
    `color: ${theme.colors.red}; 
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.regular}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.label};`};
    ${({ send }) => (send ? `width: 230px;` : `width: 300px;`)};
    ${({ send, conversion }) =>
      send
        ? 'bottom: -75px;'
        : !send && conversion
        ? 'bottom: -35px'
        : 'bottom: -18px;'};
    padding-top: 4px;
  }
`;

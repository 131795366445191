/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 14px;
  white-space: pre-wrap;
  font-size: 15px;
  ${({ theme }) => `font-weight: ${theme.fontWeight.light}`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.label};`};
  }
`;

import React from 'react';
import { Wrapper } from './hamburger.styled';

interface HamburgerProps {
  open: boolean;
  onClick: () => void;
}

const Hamburger = ({ open, onClick }: HamburgerProps) => {
  return (
    <Wrapper onClick={onClick} open={open}>
      <span />
    </Wrapper>
  );
};

export default Hamburger;

const size = {
  xs: '720px',
  md: '990px',
  lg: '1200px',
};

const deviceSize = {
  mobile: 720,
  xsmall: 320,
  normalMobile: 375,
  small: 576,
  normal: 640,
  medium: 768,
  large: 992,
  desktop: 1080,
  xlarge: 1200,
  xxlarge: 1440,
};

const device = {
  xs: `(max-width: ${size.xs})`,
  md: `(max-width: ${size.md})`,
  lg: `(min-width: ${size.xs})`,
};

const devices = {
  xsmall: `(min-width: ${deviceSize.xsmall}px)`,
  normalMobile: `(min-width: ${deviceSize.normalMobile}px)`,
  small: `(min-width: ${deviceSize.small}px)`,
  normal: `(min-width: ${deviceSize.normal}px)`,
  medium: `(min-width: ${deviceSize.medium}px)`,
  large: `(min-width: ${deviceSize.large}px)`,
  desktop: `(min-width: ${deviceSize.desktop}px)`,
  xlarge: `(min-width: ${deviceSize.xlarge}px)`,
  xxlarge: `(min-width: ${deviceSize.xxlarge}px)`,
};

const dimensions = {
  outerWrapper: 1920,
  wideSection: 1365,
  topSection: 1260,
  innerWrapper: 1080,
  topbarHeight: 40,
};

const ui = {
  transition: (prop, multiplier = 1) => `${prop} ${multiplier * 200}ms ease-in`,
  opacity: (value) => `${value || 0.8}`,
};

const theme = {
  colors: {
    primary: '#F1C10A',
    darkPrimary: '#F1C10A',
    disabled: '#CBCCD4',
    black: '#000000',
    darkGray: '#767889',
    lightGray: '#F1F1F1',
    textRed: '#910734',
    white: '#ffffff',
    red: '#E2463D',
    secondary: '#d6b56d',
    buttonDisabledYellow: '#eadbb6',
    primaryMedium: '#5e0126',
    quaternary: '#860a39',
    yellow: '#C89C65',
    green: '#65D39F',
    darkGreen: '#3ab77c',
  },
  fontSize: {
    small: '10px',
    label: '12px',
    regular: '14px',
    medium: '16px',
    middle: '18px',
    title: '20px',
    mobileTitleBig: '24px',
    desktopTitle: '28px',
    menuTitle: '32px',
    desktopBig: '48px',
  },
  fontWeight: {
    light: 300,
    regular: 400,
    bold: 500,
    extraBold: 700,
  },
  borderRadius: '15px',
  size,
  device,
  devices,
  deviceSize,
  dimensions,
  ui,
};

export default theme;

import styled from 'styled-components';

export const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 3;
`;

export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 37px 0px;

  @media ${({ theme }) => theme.device.xs} {
    padding: 20px 0px;
  }
`;

export const FooterText = styled.div`
  padding-right: 24px;
  font-weight: 500;
  font-size: 15px;

  @media ${({ theme }) => theme.device.xs} {
    padding-bottom: 10px;
    padding-right: 0px;
  }
`;

export const SocialMediaWrapper = styled.aside`
  display: flex;
  grid-template-columns: repeat(2, 25px);
  grid-gap: 10px;
  margin-top: 5px;

  @media ${({ theme }) => theme.device.xs} {
    display: grid;
    grid-template-columns: repeat(7, 25px);
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.53;
`;

export const FooterTopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 18px;

  @media ${({ theme }) => theme.device.xs} {
    flex-direction: column;
  }
`;

export const FooterBottomText = styled.div`
  font-weight: 300;
  font-size: 15px;
`;

import styled from 'styled-components';

export const FieldWrapper = styled.div<{ hasExtra: string | undefined }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 649px;
  padding: 20px 26px;
  ${({ theme }) => `background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius};`};
  ${({ hasExtra }) => hasExtra && 'flex-wrap: wrap;'};
  @media ${({ theme }) => `${theme.device.xs}`} {
    padding: 12px;
  }
`;

export const FieldLabel = styled.div<{ direction: string }>`
  line-height: 24px;

  ${({ direction }) => (direction === 'rtl' ? 'text-align: right;' : '')};

  ${({ theme }) => `font-size: ${theme.fontSize.title};
  color: ${theme.colors.darkGray};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.regular};`};
  }
`;

export const FieldValue = styled.div<{
  direction: string;
  limitedWidth: boolean;
}>`
  line-height: 24px;
  word-wrap: break-word;
  ${({ direction }) => (direction === 'rtl' ? 'text-align: right;' : '')};
  ${({ limitedWidth }) =>
    limitedWidth && `max-width: 430px; text-align: right;`};

  ${({ theme }) => `font-size: ${theme.fontSize.title};
  font-weight: ${theme.fontWeight.bold};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.regular};`};
    ${({ limitedWidth }) => limitedWidth && `max-width: 140px;`};
    text-align: right;
  }
`;

export const FieldDescription = styled.div<{ direction: string }>`
  line-height: 17px;
  flex-direction: column;
  flex-basis: 100%;
  margin-top: 10px;
  ${({ direction }) => (direction === 'rtl' ? 'text-align: right;' : '')};

  ${({ theme }) => `font-size: ${theme.fontSize.regular};
  color: ${theme.colors.darkGray};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    ${({ theme }) => `font-size: ${theme.fontSize.label};`};
    flex-basis: 100%;
    margin-top: 7px;
  }
`;

export const ExtraWrapper = styled.div`
  flex-direction: column;
  flex-basis: 100%;
`;

import { Network } from 'bitcoinjs-lib';

export const elcashNetworkMainnet: Network = {
  messagePrefix: '\x18Bitcoin Signed Message:\n',
  bech32: 'elcash',
  bip32: {
    public: 0x0488b21e,
    private: 0x0488ade4,
  },
  pubKeyHash: 0x21, // 33 dec
  scriptHash: 0x57, // 87 dec
  wif: 0x80,
};
export const elcashNetworkTestnet: Network = {
  messagePrefix: '\x18Bitcoin Signed Message:\n',
  bech32: 'telcash',
  bip32: {
    public: 0x043587cf,
    private: 0x04358394,
  },
  pubKeyHash: 0x41, // 65 dec
  scriptHash: 0x5c, // 92 dec
  wif: 0xef,
};

import styled from 'styled-components';

export const CodeInput = styled.div`
  width: 330px;
  letter-spacing: 2px;
  line-height: 24px;

  ${({ theme }) => `
  border-radius: ${theme.borderRadius};
  font-size: ${theme.fontSize.title};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    width: 279px;
  }
`;

export const CodeRefresh = styled.div`
  position: relative;
  margin-top: 25px;
  margin-bottom: 24px;
  cursor: pointer;

  ${({ theme }) => `color: ${theme.colors.primary};
  font-weight: ${theme.fontWeight.extraBold};
  font-size: ${theme.fontSize.middle}
  border-radius: ${theme.borderRadius};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    margin-top: 24px;
  }
`;

export const Input = styled.input`
  width: 45px;
  font-weight: bold;
  line-height: 24px;
  border: none;
  border-radius: 12px;
  padding: 17px;

  ${({ theme }) => `background-color: ${theme.colors.lightGray};
  font-size: ${theme.fontSize.title};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    width: 40px;
    padding: 15px;

    ${({ theme }) => `font-size: ${theme.fontSize.medium};`};
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const InputElements = styled.div<{ error: string | undefined }>`
  display: flex;
  justify-content: space-between;
  > * {
    ${({ error, theme }) =>
      error ? `color: ${theme.colors.red};` : `color: ${theme.colors.black};`};
  }
`;

export const VerificationCodeWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  top: 55px;
  margin-top: 6px;
  font-weight: 500;
  font-size: 15px;
  ${({ theme }) => `color: ${theme.colors.red};`};

  @media ${({ theme }) => `${theme.device.xs}`} {
    left: 0;
    ${({ theme }) => `font-size: ${theme.fontSize.label};`};
  }
`;
